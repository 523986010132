window.addEventListener('DOMContentLoaded', () => {
  const swiper = new Swiper('#swiper-articles', {
    slidesPerView: 1,
    spaceBetween: 50,
    pagination: {
      el: '#pagination-articles',
    },
    navigation: {
      nextEl: '#btn-next-articles',
      prevEl: '#btn-prev-articles',
    },
    breakpoints: {
      992: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
  });
});
